@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Source+Sans+Pro:wght@400;700&family=Roboto:wght@400;700&display=swap');

:root {
  /* Primary Colors */
  --primary-color: #2677fd; /* Azul Mago */
  --secondary-color: #1db1dd; /* Teal 2 */
  --accent-color: #22cddd; /* Teal 1 */
  --highlight-color: #31eede; /* Verde Resenha */
  --contrast-color: #26dbfd; /* Azul Loope */

  /* Secondary Colors */
  --purple: #6200ea; /* Roxo */
  --yellow: #ffc81a; /* Amarelo */
  --orange: #ff740d; /* Laranja */
  --green: #00c853; /* Verde */

  /* Neutral Colors */
  --black: #000000;
  --white: #ffffff;
  --gray-dark: #424249;
  --gray-medium: #35353d;
  --gray-light: #2d2d33;
  --gray-soft: #1e1e1e;

  /* Background & Text */
  --background-color: var(--white);
  --text-color: var(--white);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  background: #F8F9FA;
  color: var(--text-color);
  line-height: 1.6;
}

h1, h2, h4, h5, h6 {
  font-family: 'Lato', sans-serif;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

h3 {
  font-family: 'Lato', sans-serif;
  color: var(--black);
  margin-bottom: 5px;
  font-size: 20px;
}

p, span, li {
  font-family: 'Source Sans Pro', sans-serif;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/* ======= Buttons ======= */

button {
  background-color: var(--primary-color);
  color: var(--white);
  font-family: 'Lato', sans-serif;
  border: none;
  padding: 10px 15px;
  width: 48%;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.2s ease-in-out;
}

button:hover,
button:focus {
  background-color: var(--purple);
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft glow */
}

button:active {
  background-color: var(--purple);
  transform: scale(0.98); /* Press-down effect */
}

button:disabled {
  background-color: var(--gray-medium);
  cursor: not-allowed;
  opacity: 0.6;
  box-shadow: none;
}

/* ======= Form Inputs ======= */
input, textarea {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid var(--gray-medium);
  border-radius: 10px;
  font-family: 'Source Sans Pro', sans-serif;
  outline: none;
  transition: border 0.2s ease-in-out;
}

input:focus, textarea:focus {
  border-color: var(--primary-color);
}

/* ======= Graph Styles ======= */
.graph {
  font-family: 'Roboto', sans-serif;
  color: var(--highlight-color);
}

/* ======= Utility Classes ======= */

html, body, #__next {
  height: 100%;
  margin: 0;
  padding: 0;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #DFF6FF;
  min-height: 100vh; /* Ensures it fills the entire viewport */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}



.text-primary {
  color: var(--primary-color);
}

.text-secondary {
  color: var(--secondary-color);
}

.text-contrast {
  color: var(--contrast-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-secondary {
  background-color: var(--secondary-color);
}

.bg-contrast {
  background-color: var(--contrast-color);
}

.text-center {
  text-align: center;
}

.hidden {
  display: none;
}

/* === MAIN CONTAINER === */
.main-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

/* === LOGO === */
.logo {
  width: 200px; /* Update width */
  height: 44px; /* Update height */
  padding: 5px;
  margin-left: auto; /* Pushes the logo to the right */
  text-align: "right";
}

.logo-container {
  position: relative;
  display: flex;
  justify-content: space-between; /* Pushes home button left, logo right */
  padding: 10px 10px 1px 10px;

}


/* Full page styling */
.landing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #2677fd; /* Blue background */
  color: white;
  text-align: center;
}

/* Logo container */
.landing-logo-container {
  margin-bottom: 20px;
  width: 100%;
}

.logo-landing {
  max-width: 80%; /* Adjust as needed */
}

.landing-subtitle-container {
  font-size: 16px; /* Update font size */
  font-family: 'Lato', sans-serif; /* Update font family */
  font-weight: normal;
  color: ffffff;
}

/* Text styling */
.text-container h1 {
  font-size: 24px;
  font-weight: bold;
}

/* === PROFILE SECTION === */
.profile-section {
  display: flex;
  align-items: flex-end; /* Align text at the bottom */
  gap: 10px;
  max-width: 600px;
  margin-top: 0px;
  margin-bottom: 20px;
  width: 100%;
}

.profile-pic {
  border-radius: 50%;
  width: 110px; 
  height: 110px; 
  -o-object-fit: cover; 
     object-fit: cover;
}

.welcome-text {
  font-size: 30px; /* Update font size */
  font-family: 'Lato', sans-serif; /* Update font family */
  font-weight: bold;
  margin-left: 10px;
  margin-bottom: 0px;
}


/* === BUTTONS === */

/* positions the gear in top-left corner, small size, gray color */
.gear-button {
  position: absolute; /* or relative if you have a containing header bar */
  top: 0px;
  left: 0px;
  cursor: pointer;
}

.gear-button svg {
  fill: #BBB ; /* gray color */
  width: 26px;
  height: 30px;
  transition: fill 0.2s;
}

.gear-button svg:hover {
  fill: #333; /* darker on hover */
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 30px;
  margin-bottom: 20px; /* Add space between buttons and profile pic */
}

/* Home Button - Always on the Left */

.home-container{
  width: 100%;
  max-width: 800px;
  height: 100vh;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
  
}


.home-button {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  transition: background 0.2s ease-in-out;
}

.button-home {
  background-color: var(--primary-color);
  color: var(--white);
  font-family: 'Lato', sans-serif;
  border: none;
  padding: 8px 10px;
  width: 48%;
  max-width: 100%;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out, transform 0.1s ease-in-out, box-shadow 0.2s ease-in-out;
}

button.home:hover,
button.home:focus {
  background-color: var(--purple);
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft glow */
}

/* Home Icon inside the button */
.home-icon {
  width: 24px;
  height: 24px;
  stroke: #333; /* Icon color */
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none; /* No background fill */
}


.home-divider{
  color: var(--secondary-color);
}

.caderno-alert {
  background-color: #CFE2FF; /* Light blue with more saturation */
  border-radius: 8px;
  padding: 10px 15px;
  margin: 20px 0;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.caderno-alert-text {
  margin: 0;
  font-size: 1rem;
  color: #003E8A; /* Darker navy blue for contrast */
}




/* === RESULTS CONTAINER === */
.home-results-container {
  height: auto !important;
  padding: 5px;
  border-radius: 0px;
  margin-top: 0px;
}


/* === RESULT CARD (Clickable) === */

.result-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F8FDFF;
  padding: 10px 5px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  position: relative;
  width: 100%;
  height: 60px;
  margin: 10px auto;
}

.delete-button {
  font-size: 10px;
  width: 10px;
  height: 10px;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 0px;     /* 10px from the top edge */
  right: 0px;   /* 10px from the right edge */
  color: #888;
}

.delete-button:hover {
  color: none;
}
.delete-button:active {
  background-color: none;
}


/* Hover effect for better UX */
.result-card:hover {
  background-color: rgba(189, 223, 247, 1); /* Slightly darker blue on hover */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  transform: scale(1.02); /* Subtle zoom effect */
}

/* Click effect */
.result-card:active {
  background-color: rgba(189, 223, 247, 1); /* Keep the hover color on click */
  transform: scale(0.98); /* Press-down effect */
}

/* Content inside the result card */
.result-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 5px;
  color: var(--gray-dark);
  font-weight: bold;
}

.result-score {
  font-size: 20px;
  font-weight: normal;
  width: 100%;
  height: 100%;
}

.result-number{
  color: #6200ea; /* Purple tone */
  font-weight: bold;
}


.result-view-link {
  font-size: 12px;
  font-weight: bold;
  color: var(--primary-color);
}

/* No Tests Placeholder */
.no-tests {
  font-size: 14px;
  color: var(--gray-medium);
  text-align: center;
  padding: 10px;
}

/* === INPUT PAGE STYLES === */
.input-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
  text-align: left;
}

/* === TITLE (h1) === */
.input-title {
  font-size: 25px;
  margin-bottom: 15px;
  margin-top: 20px;
  font-family: "Lato", sans-serif;
}

/* === QUESTION CONTAINER === */
.question-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

/* === QUESTION NUMBER === */
.question-number {
  width: 30px;
  font-weight: bold;
  text-align: left;
  font-size: 25px;
  font-family: "Roboto", sans-serif;
}

/* === RADIO BUTTON GROUP === */
.radio-group {
  display: flex;
  gap: 10px;
}

/* === RADIO BUTTON LABEL === */
.radio-label {
  position: relative;
  cursor: pointer;
}

/* === HIDDEN RADIO INPUT === */
.radio-label input {
  display: none;
}

/* === CUSTOM RADIO BUTTON STYLING === */
.radio-label span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #ccc;
  font-weight: bold;
  font-size: 23px;
  font-family: "Roboto", sans-serif;
  transition: 0.2s;
  background-color: transparent;
  color: #000;
}

/* === SELECTED RADIO BUTTON STYLE === */
.radio-label input:checked + span {
  border-color: #2677fd;
  background-color: #2677fd;
  color: #fff;
}

/* === CHART STYLES === */
.chart-section {
  margin-bottom: 20px;
}

.chart-title {
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: left;
  margin-bottom: 2px;
  margin-top: 10px;
  color: var(--primary-color);
}

.chart-container {
  width: 100%;
  height: 400px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 0px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
  max-width: 800px;
}

.chart-container-bar {
  width: 100%;
  height: 500px;
  background: var(--white);
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
  max-width: 800px;
}

.chart-divider {
  border: 1px solid #26dbfd;
  margin: 15px 0;
  max-width: 800px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
  max-width: 800px;
}

/* === TEXT STYLING === */
.results-title-container {
  padding: 5px;
  border-radius: 0px;
  margin-top: 10px;
  align-items: flex-start !important;
}

.results-title {
  font-size: 34px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  color: var(--primary-color);
  margin-bottom: 10px;
  margin-top:10px;
  padding-top: 10px;
  text-align: center;
}

.results-demo-text {
  font-size: 20px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
  color: var(--purple);
  margin-bottom: 0px;
  margin-top: 10px;
  padding-top: 0px;
  text-align: center;
}

.results-subtitle-container {
  border-radius: 10px;
  margin-top: 10px;
  padding-top:10px;
  padding-bottom: 10px;
  background-color: var(--white);
  align-items: flex-start !important;
  max-width: 800px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
}

.results-demo {
  font-size: 20px;
  text-align: center;
  font-weight: normal;
  font-family: "Lato", sans-serif !important;
  color: var(--purple);
}


.results-subtitle {
  font-size: 18px;
  margin-bottom: 10px;
  margin-left: 10px;
  font-weight: normal;
  font-family: "Lato", sans-serif !important;
  color: #000000;
  margin-bottom: 5px;
}

.subtitle-number-corretas{
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  color:#00c853;
}

.subtitle-number-erradas{
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  color: #ff740d;
}

.subtitle-number-emBranco{
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  color: var(--yellow);
}

.subtitle-number-accuracy{
  font-weight: bold;
  font-family: "Lato", sans-serif;
  font-size: 22px;
  color: var(--purple);
}


.results-description {
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  color: var(--gray-dark);
  line-height: 1.5;
}

/* === RESULTS TABLE === */

.results-table-container {
  border-radius: 0px;
  margin-top: 10px;
  padding-top:10px;
  padding-bottom: 10px;
  margin-left: 0px;
  margin-right: 0%;
  background-color: var(--white);
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
  max-width: 800px;
}


.results-table {
  width: 100%;
  max-width: 400px;
  border-collapse: collapse;
  align-items: center;
  border-radius: 10px;
  overflow: hidden; /* Ensures border-radius applies */
  margin-bottom: 20px;
  border-color: #2677fd;
  border-width: 1px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
  max-width: 500px;
}

/* Table Head */
.results-table thead {
  background: var(--primary-color);
  color: var(--white);
  font-family: "Lato", sans-serif;
  text-align: left;
}

.results-table th {
  padding: 12px;
  font-weight: bold;
  text-align: center;
}

/* Table Body */
.results-table tbody tr {
  border-bottom: 1px solid var(--gray-light);
  text-align: center;
  font-family: "Roboto", sans-serif;
}

.results-table td {
  border-collapse: collapse; /* Ensures borders don't double */
  width: 100%;
  border: 1px solid #ddd; /* Light gray border */
  padding: 12px;
  color: var(--gray-dark);
  text-align: center;
}

.results-table tbody tr:hover {
  background: var(--gray-lighter);
  transition: 0.2s ease-in-out;
}


.answers-container {
  margin-top: 20px;
  padding: 15px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
  max-width: 800px;
}

.answers-title {
  font-size: 20px;
  font-weight: bold;
  color: #2677fd;
  margin-bottom: 10px;
  max-width: 400px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
}

.answer-group {
  margin-bottom: 15px;
  max-width: 400px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
}

.answer-subject {
  font-size: 16px;
  font-weight: bold;
  color: #424249;
  margin-bottom: 5px;
}

.answer-list {
  list-style: none;
  padding: 0;
}

.answer-item {
  background: #f8f9fa;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 15px;
  color: #35353d;
}

.no-blank-message {
  font-size: 14px;
  color: #35353d;
  background: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  margin-top: 10px;
  max-width: 400px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin:0 auto;
}

/* === Exam Selection Page Styles === */
.exam-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
}

.exam-title {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  text-align: "left";
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 0px;
  margin-top: 20px;
}

/* Grid System for Exams */
.exam-grid {
  display: grid;
  margin-top: 30px;
  grid-template-columns: 1fr; /* Default to one column for mobile */
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
}

/* Responsive Layout */
@media (min-width: 768px) { /* Tablet and up */
  .exam-grid {
      grid-template-columns: repeat(2, 1fr);
  }
}

/* Exam Card Styles */
.exam-card {
  background: var(--white);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  padding: 20px;
  font-size: 18px;
  color: var(--primary-color);
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: left;
  cursor: pointer;
  transition: 0.2s;
}

.exam-card:hover {
  background: var(--purple);
  color: var(--white);
}

/* === INPUT PAGE STYLES === */
.input-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 5px;
  margin-bottom: 30px;
  align-content: center;
}

.input-title {
  font-size: 24px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 0px;
}

/* === ANSWER SELECTION GRID === */

.answer-container {
  margin-top:30px;
  display: flex;
  flex-direction: column; /* Keeps questions stacked */
  align-items: center; /* Centers content horizontally */
  justify-content: center; /* Centers content vertically if needed */
  width:100%
}

.answer-grid {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.answer-row {
  display: flex;
  align-items: left;
  gap:8px;
  margin-right: 0px;
}

.question-number {
  width: 40px;
  font-weight: bold;
  color: var(--primary-color);
  text-align: right;
  margin-top: 15px;
  margin-right: 5px;
  font-size: 26px;
  font-family: "Roboto", sans-serif;
}

/* === RADIO BUTTON STYLES === */
.answer-options {
  display: flex;
  gap: 10px;
}

.answer-label {
  position: relative;
  cursor: pointer;
}

/* Ensure labels don't get the focus outline */
.answer-label:focus,
.answer-label:focus-within,
.answer-input:focus + .answer-circle,
.answer-input:focus-visible {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.answer-input {
  display: none;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  pointer-events: none; /* Prevents interactions */
}
.answer-input:focus,
.answer-input:active {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.answer-circle {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid var(--secondary-color);
  font-weight: bold;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  /* transition: 0.1s ease-in-out; */
  background-color: var(--white);
  color: var(--black);
  outline: none !important;
  box-shadow: none !important;
}


.answer-input:checked + .answer-circle {
  border-color: var(--purple);
  background-color: var(--purple);
  color: var(--white);
}

/* Disable focus indicator in Chrome, Firefox, and Safari */
*:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* === DOWNLOAD EXAMS PAGE === */
.download-container {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;
  max-height: 100vh;
}

.download-title {
  font-size: 28px;
  font-family: "Lato", sans-serif;
  font-weight: bold;
  color: var(--primary-color);
  margin-bottom: 5px;
  margin-top: 20px;
  text-align: center;
}

.download-grid {
  margin-top:40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 15px;
  gap: 15px;
  justify-content: center;
}

.download-card {
  background: var(--white);
  border: 2px solid var(--primary-color);
  border-radius: 10px;
  padding: 15px;
  font-size: 20px;
  color:var(--primary-color);
  font-weight: bold;
  font-family: "Lato", sans-serif;
  text-align: center;
  cursor: pointer;
  transition: 0.2s;
}

.download-card:hover {
  background: var(--primary-color);
  color: var(--white);
}


/* Login Page Styles */
.login-container {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  text-align: center;
}

.login-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: white;
}

.google-login {
  background-color: var(--green);
}

.email-login {
  background-color: #34A853;
}

.login-input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.login-error {
  color: red;
  font-size: 14px;
}


/* Profile Completion Page */
.complete-container {
  display: flex;               /* Enables flexbox */
  justify-content: center;      /* Centers horizontally */
  align-items: center;          /* Centers vertically */
  height: 100vh;                /* Full viewport height */
  background-color: #DFF6FF;    /* Light blue background */
  width: 100%; 
}

.profile-container {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;                /* Ensures a fixed width */
  text-align: center;
  display: flex;               /* Flexbox for internal layout */
  flex-direction: column;      /* Stacks elements vertically */
  align-items: center;         /* Centers text inputs and button */
}


.profile-input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.profile-button {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  background-color: #34A853;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.profile-error {
  color: red;
  font-size: 14px;
}


/* === UPGRADE SECTION - TEMPORARY === */

.upgrade-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  align-self: center;
  
}

.upgrade-button {
  background-color: #00c853;
  color: white;
  transition: background-color 0.2s ease-in-out;
  margin-top: 30px;
  width: 80%;
}

.upgrade-button-home {
  background-color: #00c853;
  color: white;
  transition: background-color 0.2s ease-in-out;
  margin-top: 0px;
  width: 100%;
  max-width: 780px;
}

.upgrade-button:hover {
  background-color: #009e4f;
}

.upgrade-content {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  align-items: center;
  text-align: center;
}

.upgrade-avatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 15px;
}

.upgrade-title {
  font-size: 26px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.upgrade-text {
  font-size: 18px;
  color: #555;
}

/* Settings Page Container */
.settings-container {
  max-width: 400px;
  margin: 60px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Title */
.settings-title {
  margin-bottom: 16px;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
}

/* List Wrapper */
.settings-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Shared Item Styles */
.settings-item {
  display: block;
  width: 100%;
  padding: 12px 16px;
  background-color: #f9f9f9;
  border-radius: 6px;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  text-align: center;
  transition: background-color 0.2s;
  cursor: pointer;
}

/* Hover effect */
.settings-item:hover {
  background-color: #eee;
}

/* Logout button styling (subtle highlight) */
.logout-button {
  background-color: #ffecec;
  border: none;
  text-align: center;
  cursor: pointer;
}

.logout-button:hover {
  background-color: #ffd6d6;
}

/* Contact Email Display */
.contact-email {
  margin-top: 20px;
  text-align: center;
  font-size: 0.9rem;
  color: #000; /* Updated to black */
}

/* === Modals === */
.modals {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.2);
  z-index: 9999;
  max-width: 400px;
  width: 90%;
  text-align: center; /* Centers text in the modal */
}

.modals h2 {
  margin-bottom: 20px;
  font-size: 1.2rem;
}

.upgrade-button {
  width: 100%;
}

/* ===== Landing Page Container ===== */
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Inicia do topo */
  justify-content: flex-start;
  min-height: 100vh;  /* Garante que o fundo azul cubra toda a viewport */
  background-color: #2677fd; /* Fundo azul */
  color: white;
  text-align: center;
  padding-top: 20px;
}

/* ===== Header ===== */
.landing-header {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

/* CTA (botão) */
.landing-cta .button-landing {
  background-color: var(--green);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.2s ease-in-out;
  margin-bottom: 20px;
  align-self: center;
}

.landing-cta .button-landing:hover {
  background-color: var(--purple);
}

/* Botão Login no topo direito */
.button-login {
  position: fixed;
  top: 80px;
  width: 90px;
  right: 20px;
  background-color: var(--green);
  color: var(--white);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-weight: bold;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  z-index: 1000; /* Garante que o botão fique acima de outros elementos */
}

.button-login:hover {
  background-color: var(--purple);
}

/* ===== Main Section ===== */
.landing-main {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1200px;  
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: #2677fd;
}

/* Coluna de Texto */
.landing-text {
  flex: 1 1;
  min-width: 300px;
  padding: 10px;
  text-align: left;
}

.landing-text h1 {
  font-family: 'Lato', sans-serif;
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #ffffff;
}

.landing-text p,
.landing-text ul,
.landing-text ol,
.landing-text li {
  font-family: 'Source Sans Pro', sans-serif;
  color: #ffffff;
  margin-bottom: 1rem;
}

.landing-text ul,
.landing-text ol {
  margin-left: 1.5rem;
}

.landing-text h3 {
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  margin-bottom: 5px;
  color: #ffffff;
}

/* Coluna de Vídeo */
.landing-video {
  flex: 1 1;
  min-width: 300px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centraliza verticalmente */
  align-items: center;     /* Centraliza horizontalmente */
  /* Removido height fixo para evitar problemas no mobile */
}

.landing-video video {
  width: 60%;
  max-width: 300px;
  cursor: pointer;
  transition: width 0.3s ease-in-out;
  border-radius: 10px;
  margin-top: 30px;
}

.landing-video video.expanded {
  width: 100%;
}

/* ===== Media Query para Mobile ===== */
@media (max-width: 768px) {
  .landing-main {
    flex-direction: column;
    align-items: center;
  }
  .landing-video video {
    width: 80%; /* Ajusta o tamanho do vídeo para mobile */
  }
}

/* ===== Footer ===== */
.landing-footer {
  width: 100%;
  padding: 20px;
  padding-top: 20px;
  margin-top: 0px;
  text-align: center;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #2677fd;
  color: var(--gray-dark);
}

.landing-footer p {
  margin: 0px 0;
}

.landing-footer a {
  color: var(--primary-color);
  text-decoration: underline;
}

